import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layouts/MdxPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In case you'd like to be notified every time I post something I write, this site provides the following feed formats:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/feeds/rss.xml"
        }}>{`XML`}</a>{` - great for RSS readers or `}<a parentName="li" {...{
          "href": "https://zapier.com/apps/rss/integrations"
        }}>{`Zapier`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/feeds/feed.json"
        }}>{`JSON`}</a>{` - for the 3 other people who think `}<a parentName="li" {...{
          "href": "https://www.jsonfeed.org"
        }}>{`JSON Feed`}</a>{` is a cool idea`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      